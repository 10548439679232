//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Location from "@/components/Location";

export default {
  components: {
    Location,
  },
  props: {
    defaultData: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allCheckbox: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    list: null,
    name: null,
    loading: false,
    // 选中的成员
    checkedCities: [],
  }),
  beforeDestroy() {
    this.$refs.location.isShow(false);
  },
  methods: {
    async beforeOpen() {
      if (this.list === null) await this.queryData();
    },
    async queryData() {
      this.loading = true;
      try {
        const { res, data } = await this.$http.post(
          "/General/memberSelector.ashx",
          {
            name: this.name,
          }
        );

        if (res === 0) this.list = data;
      } catch {}
      this.loading = false;
    },
    handleCheckedCitiesChange() {},
    closeLocation() {
      this.$refs.location.isShow(false);
    },
    confirmLocation() {
      this.$refs.location.isShow(false);
      this.$emit(
        "confirm",
        this.multiple ? this.checkedCities : [this.checkedCities]
      );
    },
    sb(data) {
      return JSON.stringify(data);
    },
  },
};
