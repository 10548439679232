var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Location',{ref:"location",attrs:{"readonly":_vm.readonly,"width":"295","height":"510","phoneFull":""},on:{"beforeOpen":_vm.beforeOpen},scopedSlots:_vm._u([{key:"LocationButton",fn:function(){return [_vm._t("button")]},proxy:true}],null,true)},[_c('transition',{attrs:{"slot":"content"},slot:"content"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"root"},[_c('div',{staticClass:"top"},[_c('el-input',{attrs:{"placeholder":"请输入成员名称","prefix-icon":"el-icon-search"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.queryData()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),(_vm.multiple)?_c('el-checkbox-group',{on:{"change":_vm.handleCheckedCitiesChange},model:{value:(_vm.checkedCities),callback:function ($$v) {_vm.checkedCities=$$v},expression:"checkedCities"}},_vm._l((_vm.list),function(ref){
var Name = ref.Name;
var UsId = ref.UsId;
var Picture = ref.Picture;
var Name_Pinyin = ref.Name_Pinyin;
return _c('el-checkbox',{key:UsId,attrs:{"label":_vm.sb({ Name: Name, UsId: UsId, Picture: Picture, Name_Pinyin: Name_Pinyin })}},[_c('el-avatar',{attrs:{"src":Picture,"size":28}},[_vm._v(_vm._s(Name[0]))]),_c('span',[_vm._v(_vm._s(Name))])],1)}),1):_c('el-radio-group',{on:{"change":_vm.handleCheckedCitiesChange},model:{value:(_vm.checkedCities),callback:function ($$v) {_vm.checkedCities=$$v},expression:"checkedCities"}},_vm._l((_vm.list),function(ref){
var Name = ref.Name;
var UsId = ref.UsId;
var Picture = ref.Picture;
var Name_Pinyin = ref.Name_Pinyin;
return _c('el-radio',{key:UsId,attrs:{"label":_vm.sb({ Name: Name, UsId: UsId, Picture: Picture, Name_Pinyin: Name_Pinyin })}},[_c('el-avatar',{attrs:{"src":Picture,"size":28}},[_vm._v(_vm._s(Name[0]))]),_c('span',[_vm._v(_vm._s(Name))])],1)}),1),_c('div',{staticClass:"btm"},[_c('el-button',{attrs:{"type":"info"},on:{"click":_vm.closeLocation}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmLocation}},[_vm._v("确认")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }